require("./src/styles/global.scss");

export const onRouteUpdate = () => {
    updatePreloader();
}

function updatePreloader() {
    const preloader = (typeof document !== `undefined` ? document.querySelector('.preloader'): null)
    
    if (preloader !== undefined) {
        setTimeout(() => {
            try {
                preloader.classList.add('hide-preloader');
            } catch(e) {
                console.log(e);
            }
        }, 0);
    
            setTimeout(() => {
                try {
                    preloader.remove();
                } catch (e) {
                    console.log(e);
                }
            }, 1000)
        }
    
}