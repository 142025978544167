exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-bachata-mondays-js": () => import("./../../../src/pages/bachata-mondays.js" /* webpackChunkName: "component---src-pages-bachata-mondays-js" */),
  "component---src-pages-bachata-thursdays-js": () => import("./../../../src/pages/bachata-thursdays.js" /* webpackChunkName: "component---src-pages-bachata-thursdays-js" */),
  "component---src-pages-blog-12-little-known-facts-on-salsa-dancing-js": () => import("./../../../src/pages/blog/12-little-known-facts-on-salsa-dancing.js" /* webpackChunkName: "component---src-pages-blog-12-little-known-facts-on-salsa-dancing-js" */),
  "component---src-pages-blog-7-tried-and-tested-tips-to-dance-without-looking-awkward-js": () => import("./../../../src/pages/blog/7-tried-and-tested-tips-to-dance-without-looking-awkward.js" /* webpackChunkName: "component---src-pages-blog-7-tried-and-tested-tips-to-dance-without-looking-awkward-js" */),
  "component---src-pages-blog-bachata-classes-how-to-choose-the-right-one-for-you-js": () => import("./../../../src/pages/blog/bachata-classes-how-to-choose-the-right-one-for-you.js" /* webpackChunkName: "component---src-pages-blog-bachata-classes-how-to-choose-the-right-one-for-you-js" */),
  "component---src-pages-blog-bachata-dance-discover-its-popularity-and-why-you-should-learn-js": () => import("./../../../src/pages/blog/bachata-dance-discover-its-popularity-and-why-you-should-learn.js" /* webpackChunkName: "component---src-pages-blog-bachata-dance-discover-its-popularity-and-why-you-should-learn-js" */),
  "component---src-pages-blog-bachata-tricks-and-moves-to-master-with-classes-in-orange-county-js": () => import("./../../../src/pages/blog/bachata-tricks-and-moves-to-master-with-classes-in-orange-county.js" /* webpackChunkName: "component---src-pages-blog-bachata-tricks-and-moves-to-master-with-classes-in-orange-county-js" */),
  "component---src-pages-blog-bachata-vs-salsa-key-differences-similarities-js": () => import("./../../../src/pages/blog/bachata-vs-salsa-key-differences-&-similarities.js" /* webpackChunkName: "component---src-pages-blog-bachata-vs-salsa-key-differences-similarities-js" */),
  "component---src-pages-blog-comprehensive-guide-of-salsa-classes-for-couples-js": () => import("./../../../src/pages/blog/comprehensive-guide-of-salsa-classes-for-couples.js" /* webpackChunkName: "component---src-pages-blog-comprehensive-guide-of-salsa-classes-for-couples-js" */),
  "component---src-pages-blog-dancing-through-the-workweek-how-salsa-and-bachata-classes-bring-joy-to-busy-professionals-js": () => import("./../../../src/pages/blog/dancing-through-the-workweek-how-salsa-and-bachata-classes-bring-joy-to-busy-professionals.js" /* webpackChunkName: "component---src-pages-blog-dancing-through-the-workweek-how-salsa-and-bachata-classes-bring-joy-to-busy-professionals-js" */),
  "component---src-pages-blog-discover-the-rhythm-of-salsa-js": () => import("./../../../src/pages/blog/discover-the-rhythm-of-salsa.js" /* webpackChunkName: "component---src-pages-blog-discover-the-rhythm-of-salsa-js" */),
  "component---src-pages-blog-explore-dance-styles-and-their-fiteness-benefits-js": () => import("./../../../src/pages/blog/explore-dance-styles-and-their-fiteness-benefits.js" /* webpackChunkName: "component---src-pages-blog-explore-dance-styles-and-their-fiteness-benefits-js" */),
  "component---src-pages-blog-famous-romantic-dances-for-valentine-day-js": () => import("./../../../src/pages/blog/famous-romantic-dances-for-valentine-day.js" /* webpackChunkName: "component---src-pages-blog-famous-romantic-dances-for-valentine-day-js" */),
  "component---src-pages-blog-how-to-dance-bachata-easy-steps-for-beginners-js": () => import("./../../../src/pages/blog/how-to-dance-bachata-easy-steps-for-beginners.js" /* webpackChunkName: "component---src-pages-blog-how-to-dance-bachata-easy-steps-for-beginners-js" */),
  "component---src-pages-blog-how-to-take-an-online-dance-class-js": () => import("./../../../src/pages/blog/how-to-take-an-online-dance-class.js" /* webpackChunkName: "component---src-pages-blog-how-to-take-an-online-dance-class-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-is-salsa-good-for-beginner-js": () => import("./../../../src/pages/blog/is-salsa-good-for-beginner.js" /* webpackChunkName: "component---src-pages-blog-is-salsa-good-for-beginner-js" */),
  "component---src-pages-blog-move-your-feet-on-the-rhythms-of-bachata-music-js": () => import("./../../../src/pages/blog/move-your-feet-on-the-rhythms-of-bachata-music.js" /* webpackChunkName: "component---src-pages-blog-move-your-feet-on-the-rhythms-of-bachata-music-js" */),
  "component---src-pages-blog-salsa-night-vs-latin-night-in-orange-county-js": () => import("./../../../src/pages/blog/salsa-night-vs-latin-night-in-orange-county.js" /* webpackChunkName: "component---src-pages-blog-salsa-night-vs-latin-night-in-orange-county-js" */),
  "component---src-pages-blog-salsa-spin-master-5-vital-tricks-perfect-turns-js": () => import("./../../../src/pages/blog/salsa-spin-master-5-vital-tricks-perfect-turns.js" /* webpackChunkName: "component---src-pages-blog-salsa-spin-master-5-vital-tricks-perfect-turns-js" */),
  "component---src-pages-blog-step-up-your-dance-moves-with-salsa-dancing-classes-js": () => import("./../../../src/pages/blog/step-up-your-dance-moves-with-salsa-dancing-classes.js" /* webpackChunkName: "component---src-pages-blog-step-up-your-dance-moves-with-salsa-dancing-classes-js" */),
  "component---src-pages-blog-tackling-the-major-challenges-of-bachata-dance-js": () => import("./../../../src/pages/blog/tackling-the-major-challenges-of-bachata-dance.js" /* webpackChunkName: "component---src-pages-blog-tackling-the-major-challenges-of-bachata-dance-js" */),
  "component---src-pages-blog-tips-and-tricks-to-start-your-bachata-dance-journey-js": () => import("./../../../src/pages/blog/tips-and-tricks-to-start-your-bachata-dance-journey.js" /* webpackChunkName: "component---src-pages-blog-tips-and-tricks-to-start-your-bachata-dance-journey-js" */),
  "component---src-pages-blog-top-salsa-dance-moves-for-beginners-js": () => import("./../../../src/pages/blog/top-salsa-dance-moves-for-beginners.js" /* webpackChunkName: "component---src-pages-blog-top-salsa-dance-moves-for-beginners-js" */),
  "component---src-pages-blog-transform-you-confidence-with-salsa-classes-js": () => import("./../../../src/pages/blog/transform-you-confidence-with-salsa-classes.js" /* webpackChunkName: "component---src-pages-blog-transform-you-confidence-with-salsa-classes-js" */),
  "component---src-pages-blog-what-to-wear-for-salsa-dancing-complete-guide-js": () => import("./../../../src/pages/blog/what-to-wear-for-salsa-dancing-complete-guide.js" /* webpackChunkName: "component---src-pages-blog-what-to-wear-for-salsa-dancing-complete-guide-js" */),
  "component---src-pages-blog-why-adults-should-embrace-the-dance-floor-and-start-their-journey-js": () => import("./../../../src/pages/blog/why-adults-should-embrace-the-dance-floor-and-start-their-journey.js" /* webpackChunkName: "component---src-pages-blog-why-adults-should-embrace-the-dance-floor-and-start-their-journey-js" */),
  "component---src-pages-bookings-js": () => import("./../../../src/pages/bookings.js" /* webpackChunkName: "component---src-pages-bookings-js" */),
  "component---src-pages-cancellations-js": () => import("./../../../src/pages/cancellations.js" /* webpackChunkName: "component---src-pages-cancellations-js" */),
  "component---src-pages-classes-bachata-dance-classes-in-orange-county-ca-js": () => import("./../../../src/pages/classes/bachata-dance-classes-in-orange-county-ca.js" /* webpackChunkName: "component---src-pages-classes-bachata-dance-classes-in-orange-county-ca-js" */),
  "component---src-pages-classes-cumbia-dance-classes-in-orange-county-ca-js": () => import("./../../../src/pages/classes/cumbia-dance-classes-in-orange-county-ca.js" /* webpackChunkName: "component---src-pages-classes-cumbia-dance-classes-in-orange-county-ca-js" */),
  "component---src-pages-classes-index-js": () => import("./../../../src/pages/classes/index.js" /* webpackChunkName: "component---src-pages-classes-index-js" */),
  "component---src-pages-classes-salsa-dance-classes-in-orange-county-ca-js": () => import("./../../../src/pages/classes/salsa-dance-classes-in-orange-county-ca.js" /* webpackChunkName: "component---src-pages-classes-salsa-dance-classes-in-orange-county-ca-js" */),
  "component---src-pages-classes-series-js": () => import("./../../../src/pages/classes/series.js" /* webpackChunkName: "component---src-pages-classes-series-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-helpfull-js": () => import("./../../../src/pages/helpfull.js" /* webpackChunkName: "component---src-pages-helpfull-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kids-js": () => import("./../../../src/pages/kids.js" /* webpackChunkName: "component---src-pages-kids-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-performance-and-events-js": () => import("./../../../src/pages/performance-and-events.js" /* webpackChunkName: "component---src-pages-performance-and-events-js" */),
  "component---src-pages-privates-js": () => import("./../../../src/pages/privates.js" /* webpackChunkName: "component---src-pages-privates-js" */),
  "component---src-pages-prospect-js": () => import("./../../../src/pages/prospect.js" /* webpackChunkName: "component---src-pages-prospect-js" */),
  "component---src-pages-salsa-wednesdays-js": () => import("./../../../src/pages/salsa-wednesdays.js" /* webpackChunkName: "component---src-pages-salsa-wednesdays-js" */),
  "component---src-pages-sitemap-xml-unsused-js": () => import("./../../../src/pages/sitemap.xml.unsused.js" /* webpackChunkName: "component---src-pages-sitemap-xml-unsused-js" */),
  "component---src-pages-summar-special-index-js": () => import("./../../../src/pages/summar-special/index.js" /* webpackChunkName: "component---src-pages-summar-special-index-js" */),
  "component---src-pages-teacher-training-js": () => import("./../../../src/pages/teacher-training.js" /* webpackChunkName: "component---src-pages-teacher-training-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-weekly-classes-js": () => import("./../../../src/pages/weekly-classes.js" /* webpackChunkName: "component---src-pages-weekly-classes-js" */),
  "component---src-templates-teams-js": () => import("./../../../src/templates/teams.js" /* webpackChunkName: "component---src-templates-teams-js" */)
}

